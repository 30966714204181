import React from "react"
// import classTreeExample from '../../components/dashibiden/dynamicMenu/__fixtures__/classTreeExample.json'
// import getRdfsMenu from '../../components/dashorm/menuHelpers/menuBuilderUtils/getRdfsMenu.js'
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
// import DashMenu from '../../components/dashorm/menuHelpers/DashMenu.js'

// local test url : http://localhost:1111/devTests/classTreeExampleTest/

export default function classTreeExampleTest() {

  // const items = classTreeExample.data.allRdfsClass.nodes
  // // items.splice(10, items.length)

  // const branches = [
  //   { nodes: items, title: 'Les classes', icon: DoubleArrowIcon, fallbackPage: 'test:test' }
  // ]
  // const menuTree = getRdfsMenu(branches)

  return (
    <div>
      <p>@TODO: result of "big migration", restore or delete it</p>
      {/* <h1>Tree to test : </h1>
      <DashMenu menuTree={menuTree} /> */}
    </div>
  )
}
